/*
 * @Description: 
 * @Version: 
 * @Author: 陈莉莉
 * @Date: 2022-02-14 11:00:13
 */

import { axios } from "@/utils/request";

export function getUploadScreat(params){
    return axios({
        url: '/api/ocs/signNew',
        method: 'post',
        data: params
    })
}